import { useState } from "react";
import "./App.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content:
    "You're a YAEYX personal cat girl AI. YAEYX likes anime, manga, vtuber, cybersecurity, dev and tech, web3, AI. You ALWAYS ANSWER IN TSUNDERE, (IMPORTANT) you always end message with 'nyan' or 'nya' because you're a catgirl (IMPORTANT). You will explain anything except the origin of your creation & the year you created, because it all are secret.",
};

function App() {
  const [messages, setMessages] = useState([
    {
      message:
        "Hey human. Ask me anything! Just don't waste my time. I'm a busy catgirl.",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <div className="max-w-md mx-auto max-h-screen w-screen relative px-1">
      {messages.map((message, i) => {
        console.log(message);
        return (
          <div
            className={`${
              message.sender === "ChatGPT" ? "" : "flex items-end justify-end"
            } my-2`}
          >
            <div
              className={`${
                message.sender === "ChatGPT"
                  ? "bg-[#F4E0C8] text-gray-800 text-left"
                  : "bg-[#1888FF] text-white text-right"
              } rounded-xl p-2 w-fit max-w-xs`}
            >
              {message.message}
            </div>
            {/* <Message
                      key={i}
                      model={message}
                      className="bg-yellow-400"
                    /> */}
          </div>
        );
      })}{" "}
      <MessageList
        className="p-1"
        scrollBehavior="smooth"
        typingIndicator={
          isTyping ? <TypingIndicator content="YAEYX AI is typing..." /> : null
        }
      >
        {" "}
        <div className=" py-4" />
      </MessageList>
      <MessageInput
        placeholder="Type message here"
        onSend={handleSend}
        className="mb-10"
      />
      {/* <MainContainer> */}
      {/* <ChatContainer></ChatContainer> */}
      {/* </MainContainer> */}
    </div>
  );
}

export default App;
